import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Legal from "./pages/Legal";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
   <BrowserRouter >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/legal" element={<Legal />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
