import Navbar from "./Navbar.js";

function Legal() {
  return (
    <>
      <Navbar />
      <div className="padding">
        <div className="container">
        <h1 className="center">
       Mentions légales
       </h1>
        <br />
     <h3><b> En vigueur au 15/02/2023</b> </h3>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi
        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
        dite L.C.E.N., il est porté à la connaissance des utilisateurs et
        visiteurs, ci-après l""Utilisateur", du site isoapps.fr , ci-après le
        "Site", les présentes mentions légales. La connexion et la navigation
        sur le Site par l’Utilisateur implique acceptation intégrale et sans
        réserve des présentes mentions légales.
        <br />
        Ces dernières sont accessibles sur le Site à la rubrique « Mentions
        légales ». <br /> <br />

        <div>
        <h3><b>ARTICLE 1 - L'EDITEUR</b></h3>
        <span className="red">
        Le site isoapps.fr est la propriété exclusive de SASU Isoapps, qui l'édite.
        </span>
        <br />
        ISOAPPS
        <br />
        SASU au capital de 100 €<br />
        66 Rue de la Convention 75015 Paris
        <br />
        Immatriculée au Registre du Commerce et des Sociétés de Paris 948 782
        420
        <br />
        sous le numéro 94878242000012
        <br />
        Numéro TVA intracommunautaire : FR30948782420
        <br />
        Adresse de courrier électronique : contact@isoapps.fr <br />
        Le Directeur de la publication est Salame Mounif
        <br />
        Contactez le responsable de la publication : contact@isoapps.fr
        <br />
        ci-après l'"Editeur".
        </div>
<div>
  <br />
<h3><b>ARTICLE 2 - L'HEBERGEUR</b></h3>
        L'hébergeur du Site est la société
        <br />
        IONOS SE
        <br />
        Elgendorfer Str. 57
        <br />
        56410 Montabaur, Allemagne
</div>
        <br />
    <div>
    <h3><b>ARTICLE 3 - ACCES AU SITE</b></h3>
        Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
        majeure, interruption programmée ou non et pouvant découlant d’une
        nécessité de maintenance.
        <br />
        En cas de modification, interruption ou suspension du Site, l'Editeur ne
        saurait être tenu responsable.
    </div>
        <br />
    <div>
    <h3><b>ARTICLE 4 - COLLECTE DES DONNEES</b></h3>
        Le Site assure à l'Utilisateur une collecte et un traitement
        d'informations personnelles dans le respect de la vie privée
        conformément à la loi n°78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés.
        <br />
        En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978,
        l'Utilisateur dispose d'un droit d'accès, de rectification, de
        suppression et d'opposition de ses données personnelles. L'Utilisateur
        exerce ce droit :<br />
        - par mail a <a href="mailto:contact@isoapps.fr">contact@isoapps.fr</a>;
        <br />
        Toute utilisation, reproduction, diffusion, commercialisation,
        modification de toute ou partie du Site, sans autorisation de l’Editeur
        est prohibée et pourra entraînée des actions et poursuites judiciaires
        telles que notamment prévues par le Code de la propriété intellectuelle
        et le Code civil.
    </div>

        <br />
        </div>

      </div>
    </>
  );
}

export default Legal;
