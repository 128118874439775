import React, { useState } from "react";
import "../css/Navbar.css";
import Logo from '../media/Logo.png'
import { Link } from "react-router-dom";


 function Navbar() {
  const [navbarState, setNavbarState] = useState(false);

  return (
    <>

     <div className="navbar-root">
      <nav id="navbar">
        <div className="nav-wrapper">
          <div className="logo">
            <Link to="/home">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          {/* For desktop */}
          <div className="hide-max-769 links">
            <ul id="menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/legal">Legal Mentions</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        className={navbarState ? "menuIcon toggle" : "menuIcon"}
        onClick={() => setNavbarState(!navbarState)}
      >
        <span className="icon icon-bars"></span>
        <span className="icon icon-bars overlay"></span>
      </div>
      {/* For mobile */}
      <div
        className={
          navbarState ? "overlay-menu transformx-0 padding" : "overlay-menu"
        }
      >
        <ul id="menu">
          <li>
            <Link className="a__navbar" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="a__navbar" to="/legal">
            Legal Mentions
            </Link>
          </li>
          <li>
            <Link className="a__navbar" to="/contact">
            Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
    </>

  );
}
export default Navbar;