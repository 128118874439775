import Navbar from "./Navbar.js";
import contactVid from "../media/contactVid.webm"

function Contact() {
  return (
    <>
      <Navbar />
      <div className="padding center">
      <div className="contact-text">
       <h1>Please Contact us At:</h1>
       <h1><a href="mailto:contact@isoapps.fr">contact@isoapps.fr</a></h1>
      </div>
        <video
          width='80%'
          autoPlay
          muted
        >
          <source
            src={contactVid}
            type="video/webm"
          ></source>
        </video>

      </div>
    </>
  );
}

export default Contact;
