import Navbar from "./Navbar.js";

function Home() {
  return (
    <>
      <Navbar />
      <div className="padding">
        <div>
          {/* <img style={{width:'100%'}} src={Contruction} alt="Contruction" /> */}
          ISOAPPS est une société de développement de logiciel basée en France.
          Armée d’équipes aux compétences polyvalentes, ISOAPPS développement en
          informatique répondront à toutes vos demandes, des plus classiques aux
          plus originales. L’ampleur de votre projet importe peu. Des petites
          macros à des projets logiciels bien plus complexes, une expansion des
          produits standards existants en passant par une solution logicielle
          autonome, il n’y aura aucune zone d’ombre. De plus, qu’il s’agisse
          d’une solution WEB et collaborative vous permettant d’y accéder de
          façon totalement sécurisée via internet ou bien d’une solution
          logicielle classique, l’entreprise se chargera de choisir les
          technologies adaptées.
        </div>
      </div>
    </>
  );
}

export default Home;
